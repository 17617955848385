import React, { Component } from 'react';
import parse from "html-react-parser";
import "./CSS/Journal.css";
import JournalTextEntry from "./JournalTextEntry";


class PreviousJournalList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            journalEntries: []
        };
    }

    componentDidMount() {
        const { accessToken, username, pageID } = this.props;

        let url = ' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/journalentry/'+ username;
        if (pageID !== "All Pages") {
            url += "/" + pageID
        }
        console.log("URL: " + url)
        // Get all of the current Journal Entries for the specified user
        console.log('Retrieving previous journal entries!' + username);
        fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": accessToken,
                "Accept": 'application/json'
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log("JOURNALS" + json);
                this.setState({ journalEntries: json.journal_entries });
            })
            .catch(error => {
                console.error(error);
            });
    }


    render() {
        const { accessToken, pageID, username } = this.props;
        const { journalEntries } = this.state;

        return (
            <div style={{width: "100%"}}>
                {accessToken && (
                    <ul style={{padding: "0"}}>
                        {journalEntries.map((item, index) => (
                            <div id="prev-journal-list">
                                <JournalTextEntry
                                key={item[1]} pageID={pageID} currJournalID={item[1]}
                                accessToken={accessToken}
                                username={username}
                                deleteJournalEntry={this.props.deleteJournalEntry}
                                saveRefresh={this.props.saveRefresh}
                                entry={item[2]}
                                paragraphToggle={true}/>
                            </div>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}

export default PreviousJournalList;


// <li key={index}>
//     <div>
//         {parse(item[2])}
//     </div>
// </li>
// <button id="delete-button" onClick={() => this.props.deleteJournalEntry(item[1])}>Delete Entry</button>
//
