import React, { Component, useState } from 'react';
import JournalTextEntry from "./JournalTextEntry";
import PreviousJournalList from "./PreviousJournalList";
import "./CSS/Journal.css";
import {TfiPencilAlt} from 'react-icons/tfi'


class Journal extends Component {
    constructor(props) {
        super(props);
        const { accessToken, username, pageID } = props;
        const currJournalID = String(Date.now());

        this.state = {
            newJournalEntries: [],
            refresh: 0
        };

        this.deleteJournalEntry = this.deleteJournalEntry.bind(this);
        this.saveRefresh = this.saveRefresh.bind(this);

    }

    handleNewEntry = () => {
        const { accessToken, username, pageID } = this.props;
        const currJournalID = String(Date.now());
        this.setState(prevState => ({
            newJournalEntries: [...prevState.newJournalEntries, <JournalTextEntry key={currJournalID} pageID={pageID} currJournalID={currJournalID} accessToken={accessToken} username={username} deleteJournalEntry={this.deleteJournalEntry} saveRefresh={this.saveRefresh}/>]
        }));
        console.log(this.state.newJournalEntries + "USERNAME: " + username);
    };

    saveRefresh() {
        //this.setState({refresh: this.state.refresh + 1})
    }

    deleteJournalEntry (currJournalID) {
        console.log("IN DELETE");
        this.setState((prevState) => ({
            newJournalEntries: prevState.newJournalEntries.filter(
                (entry) => entry.key !== currJournalID
            ),
        }));
        //DELETE FROM THE DATABASE
        console.log('Deleting journal entry from DB');
        fetch(' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/journalentry/'+ this.props.username + '/' + currJournalID, {
            method: 'DELETE',
            headers: {
                "Authorization": this.props.accessToken,
                "Accept": 'application/json'
            }
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState({refresh: this.state.refresh + 1})
            })
            .catch(error => {
                console.error(error);
            });
    }

    render() {
        const { accessToken, username, pageID } = this.props;
        const { newJournalEntries, refresh } = this.state;

        return (
            <div id="journal">
                {accessToken !== "No token" && pageID ? (
                    <div id="journal-box">
                        <h2 className="journal-header">Journal Entries</h2>
                        <PreviousJournalList key={refresh} pageID={pageID}
                                             accessToken={accessToken} username={username}
                                             deleteJournalEntry={this.deleteJournalEntry}
                                             saveRefresh={this.saveRefresh}/>
                        <div id="entries-list">
                            {newJournalEntries}
                            <button className="new-entry-button" onClick={() => this.handleNewEntry()}><TfiPencilAlt size="2em"/></button>
                        </div>
                    </div>
                ) : (
                    <h3 className="sign-in-prompt">Navigate to a Page!</h3>
                )}
            </div>
        );
    }
}

export default Journal;

// <PreviousJournalList key={refresh} pageID={pageID} accessToken={accessToken} username={username} deleteJournalEntry={this.deleteJournalEntry.bind(this)} />
// import React, { Component, useState } from 'react';
// import JournalTextEntry from "./JournalTextEntry";
// import PreviousJournalList from "./PreviousJournalList";
// import "./CSS/Journal.css";
//
//
// class Journal extends Component {
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             newJournalEntries: [],
//             refresh: 0
//         };
//
//         this.deleteJournalEntry = this.deleteJournalEntry.bind(this);
//         this.saveRefresh = this.saveRefresh.bind(this);
//
//     }
//
//     handleNewEntry = () => {
//         const { accessToken, username, pageID } = this.props;
//         const currJournalID = String(Date.now());
//         this.setState(prevState => ({
//             newJournalEntries: [...prevState.newJournalEntries, <JournalTextEntry key={currJournalID} pageID={pageID} currJournalID={currJournalID} accessToken={accessToken} username={username} deleteJournalEntry={this.deleteJournalEntry} saveRefresh={this.saveRefresh}/>]
//         }));
//         console.log(this.state.newJournalEntries + "USERNAME: " + username);
//     };
//
//     saveRefresh() {
//         this.setState({refresh: this.state.refresh + 1})
//     }
//
//     deleteJournalEntry (currJournalID) {
//         console.log("IN DELETE");
//         this.setState((prevState) => ({
//             newJournalEntries: prevState.newJournalEntries.filter(
//                 (entry) => entry.key !== currJournalID
//             ),
//         }));
//         //DELETE FROM THE DATABASE
//         console.log('Deleting journal entry from DB');
//         fetch(' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/journalentry/'+ this.props.username + '/' + currJournalID, {
//             method: 'DELETE',
//             headers: {
//                 "Authorization": this.props.accessToken,
//                 "Accept": 'application/json'
//             }
//         })
//             .then(response => response.json())
//             .then(json => {
//                 console.log(json);
//                 this.setState({refresh: this.state.refresh + 1})
//             })
//             .catch(error => {
//                 console.error(error);
//             });
//     }
//
//     render() {
//         const { accessToken, username, pageID } = this.props;
//         const { newJournalEntries, refresh } = this.state;
//
//         return (
//             <div>
//                 {accessToken !== "No token" && pageID ? (
//                     <div id="journal-box">
//                         <div>
//                             <button onClick={() => this.handleNewEntry()}>New Journal Entry</button>
//                             {newJournalEntries}
//                         </div>
//                         <PreviousJournalList key={refresh} pageID={pageID} accessToken={accessToken} username={username} deleteJournalEntry={this.deleteJournalEntry.bind(this)} />
//                     </div>
//                 ) : (
//                     <h3 className="sign-in-prompt">Please sign in!</h3>
//                 )}
//             </div>
//         );
//     }
// }
//
// export default Journal;



