import React, {Component} from 'react';

class MCExercise extends Component {
    state = {
        question: this.props.question,
        answers: this.props.answers,
        correctAnswer: this.props.correctAnswer,
        answered: false
        // question: "This is example text, of a reading you would do on an extensive explanation of good principles which you should apply to your life",
        // answers: ["answer1", "answer2", "answer3"],
        // answered: false,
        // correctAnswer: "answer1"
    };

    // constructor(){
    //     super();
    //     this.handleAnswer.bind(this);
    //     console.log("Constructor", this);
    // }

    render() {
        return (
            <div>
                <h1>{this.state.question}</h1>
                {/* <ul>{this.state.answers.map(answer => <button onClick={this.handleAnswer} className="btn btn-secondary btn-lg">{answer}</button>)}</ul> */}
                <ul>{this.state.answers.map(answer => <button onClick={this.handleAnswer} className={this.getButtonStyle(answer)}>{answer}</button>)}</ul>   
                {/* <ul>{this.state.answers.map(answer => <button onClick={this.handleAnswer} className={ () => this.getButtonStyle(answer.indexOf)}>{answer}</button>)}</ul>           */}
            </div>
        
        )
    }

    handleAnswer = () => {
        console.log('Answer clicked', this);
        this.setState({answered: true})

    }
    getButtonStyle = id => {
        console.log("ID",id)
        if(this.state.answered && id === this.state.correctAnswer) return "btn btn-success btn-lg"
        if(this.state.answered) return "btn btn-danger btn-lg"
        return "btn btn-secondary btn-lg"
    }



    // formatButton() {
    //     return this.state.answered === true ? formatIncorrect(): formatCorrect()
    // }

    // formatCorrect() {
    //     return ""
    // }
    // formatIncorrect() {
    //     return ""
    // }

}
export default MCExercise;