import React, {Component} from "react";
import Journal from "./Journal";
import Sidebar from "./Sidebar";

class JournalPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        let { pageID, accessToken, username, allFiles, directoryConfig } = this.props
        if (pageID === undefined || pageID === null) {
            pageID = "All Pages"
        }
        return (
            <div id="FullPageExercise" className="home-welcome">
                <div className="container">
                    <div id='sidebar_wrapper' className="sidebar_wrapper">
                        <div id="sidebar" className="sidebar">
                            <Sidebar  allFiles={allFiles} mainDirectory={directoryConfig}
                                      onClick={this.pagesClick} directory={"Journal"}></Sidebar>
                        </div>
                    </div>
                    <div id='page_data' className="page_data">
                        <Journal class='journal' key={pageID} pageID={pageID} accessToken={accessToken} username={username}></Journal>
                    </div>
                </div>
            </div>
        );
    }
}

export default JournalPage;