import React from 'react';
import { useState } from "react";
import {RiArrowDropDownLine} from 'react-icons/ri'
import {RiArrowDropUpLine} from "react-icons/ri";
import {NavLink} from "react-router-dom";

function Sidebar({ mainDirectory, onClick, directory, allFiles}) {
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [selectedMajorDirectory, setSelectedMajorDirectory] = useState(null);

  const handleClick = (directory) => {
    let toSet = directory;
    if (directory === selectedDirectory) {
      toSet = "CLOSE";
    }
    setSelectedDirectory(toSet);
  };

  const handleMajorClick = (directory) => {
    let toSet = directory;
    if (directory === selectedMajorDirectory) {
      toSet = "CLOSE";
    }
    setSelectedMajorDirectory(toSet);
  }

  // This displays the pages underneath each chapter. The structure is very similar to the renderDirectories function
  const renderPages = (pages) => {
    let pagesList = []
    Object.keys(pages).forEach(function(key, index){
        let newDict = {}
        pagesList.push(newDict[key] = pages[key])
    });    
    return (
      <ul>
        {pagesList.map((page) => (
          <li key={page.path}>
            <a className="page_link" onClick={() => onClick(page.text, page.pageID, page.youtubeURL, page.youtubeStartTime, page.imageURL)}>{page.pageTitle}</a>
          </li>
        ))}
      </ul>
    );
  };

  // Main Directory is recieved as a prop, which is the specific section of the files which applies to the overall topic
  // The file directory is a series of nested json objects/dictionaries. It is converted here to an array to use the map function
  const renderDirectories = () => {
    let chapters = []
    let majorDirectories = []
    if (mainDirectory === undefined) {
      let localAccess = window.localStorage.getItem("fileDirectory")
      let localObject = JSON.parse(localAccess)
      let currDirectory = window.localStorage.getItem("currDirectory")
      mainDirectory = localObject["Become"]
    }
    Object.keys(allFiles).forEach(function(key, index){
      let newDict = {}
      if (key !== "LandingPages" && key !== "DailyQuotes") {
        majorDirectories.push(newDict[key] = allFiles[key])
      }
    });
    // ADD EXTRA TO PAGES
    majorDirectories.push({"name": "Journal"})
    majorDirectories.push({"name": "SnazzySuccesses"})
    // Object.keys(mainDirectory.subdirectories).forEach(function(key, index){
    //   let newDict = {}
    //   chapters.push(newDict[key] = mainDirectory.subdirectories[key])
    // });
    return (
        <div>
          {majorDirectories.map((majorDirectory) => (
              <div style={{ marginBottom: "0px"}}>
                <NavLink to={"/" + majorDirectory.name}>
                  <div style={{display: "flex"}}>
                    <a className="directory_link" onClick={() => handleMajorClick(majorDirectory)}>
                        {majorDirectory.name}
                    </a>
                    {selectedMajorDirectory === majorDirectory ? (
                        <RiArrowDropDownLine className="arrow" size="2em"
                                             onClick={() => handleMajorClick(majorDirectory)}/>
                    ) : (
                        <RiArrowDropUpLine className="arrow" size="2em"
                                           onClick={() => handleMajorClick(majorDirectory)}/>
                    )}
                  </div>
                </NavLink>
                {selectedMajorDirectory === majorDirectory &&
                    <ul>
                      {Object.values(majorDirectory.subdirectories).map((directory) => (
                          <li key={directory.path}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                              <a style={{width: "150px"}} className="subdirectory_link"
                                 onClick={() => handleClick(directory)}>
                                {directory.name}
                              </a>
                              {selectedDirectory === directory ? (
                                  <RiArrowDropDownLine className="arrow" size="2em"
                                                       onClick={() => handleClick(directory)}/>
                              ) : (
                                  <RiArrowDropUpLine className="arrow" size="2em"
                                                     onClick={() => handleClick(directory)}/>
                              )}
                            </div>
                            {selectedDirectory === directory &&
                                renderPages(directory.pages)}
                          </li>
                      ))}
                    </ul>
                }
              </div>
          ))}
        </div>
    );
  };

  return <div>{renderDirectories()}</div>;
}
export default Sidebar;

