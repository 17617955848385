import React, { Component } from "react";
import { render } from "react-dom";
import { Editor } from "@tinymce/tinymce-react";

class RichTextEditor extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { initVal } = this.props;
        if (initVal === undefined || initVal === null) {
            initVal = ""
        }
        return (
            <div>
                {/* <Global
          styles={css`
            .tox-notifications-container {
              display: none !important;
            }
          `}
        /> */}
                <Editor
                    apiKey="agr1cz8c5ee05lckfhf7x0ev9qnde6mkupgtq9e4vdnbc8mi"
                    initialValue= {initVal}
                    init={{
                        branding: false,
                        height: 400,
                        menubar: true,
                        plugins:
                            "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                        toolbar:
                            "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                        image_advtab: true
                    }}
                    onChange={this.props.onChange}
                />
            </div>
        );
    }
}

export default RichTextEditor;