import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import './CSS/Nav.css'
import SignInButton from "./SignInButton";
import Sidebar from "./Sidebar";
// import {sh} from "yarn/lib/cli";

const Navbar = ({accessToken, directoryConfig}) => {
    const [showNavbar, setShowNavbar] = useState(false)
    const navbarToggle = document.getElementById("navbar-toggle");
    const navbarMenu = document.getElementById("navbar-menu");

    // navbarToggle.addEventListener("click", () => {
    // navbarToggle.classList.toggle("show");
    // navbarMenu.classList.toggle("show");
    // });


    useEffect(() => {
        // function handleResize() {
        //     const div1 = document.getElementById('nav-elements');
        //     const div2 = document.getElementById('FullPageExercise');
        //     const margin = window.getComputedStyle(div2).getPropertyValue('margin');
        //     const left = margin.split(' ')[margin.split(' ').length - 1]
        //     div1.style.left = left;
        // }
        // window.addEventListener('resize', handleResize);
        //
        // return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)

        const sidebarWrap = document.getElementById('sidebar_wrapper')
        if (sidebarWrap.style.display === 'block') {
            sidebarWrap.style.display = 'none'
        } else {
            sidebarWrap.style.display = 'block'
        }
        // setShowNavbar(!showNavbar)
        // const div1 = document.getElementById('nav-elements');
        // const div2 = document.getElementById('FullPageExercise');
        // const margin = window.getComputedStyle(div2).getPropertyValue('margin');
        // const left = margin.split(' ')[margin.split(' ').length - 1]
        // div1.style.left = left;
        // if (showNavbar === false && window.innerWidth > 950) {
        //     document.getElementById('sidebar').style.display = 'none'
        // } else {
        //     document.getElementById('sidebar').style.display = 'block'
        // }
    }

    function handleSignOut() {
        window.history.pushState({}, document.title, "/");
        window.localStorage.removeItem("access_token");
        window.location.reload();
    }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <span className="menu-icon__hamburger">&#8801;</span>
                    {/* <h1>&#8801;</h1> */}
                </div>
                <div className="logo">
                    <NavLink to="/">
                        <img id="logo" className="logo" src="NewLogo.png"/>
                    </NavLink>
                </div>
                <div>
                    { accessToken === "No token" ? (
                        <SignInButton/>
                    ) : (
                        <button className="sign-in-button" onClick={handleSignOut}>Sign out</button>
                    )}
                </div>

            </div>
        </nav>
    )
}

export default Navbar

// {/*<div id='nav-elements' className={`nav-elements  ${showNavbar && 'active'}`}>*/}
// {/*    <ul>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/">Home</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/become">Become</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/belong">Belong</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/growth_ideas">Growth Ideas</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/mindfulness">Mindfulness</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/relational_growth">Relational Growth</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/body_image">Body Image</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/life_perspective">Life Perspective</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/sense_of_self">Sense of Self</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/journal">Journal</NavLink>*/}
// {/*        </li>*/}
// {/*        <li>*/}
// {/*            <NavLink onClick={handleShowNavbar} to="/snazzy">Snazzy Successes</NavLink>*/}
// {/*        </li>*/}
// {/*        /!* <li>*/}
// {/*            <NavLink to="/contact">Contact</NavLink>*/}
// {/*        </li> *!/*/}
// {/*    </ul>*/}
// {/*</div>*/}
