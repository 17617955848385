import React, {Component, useEffect} from 'react';
import Exercise from './Exercise';
import Markdown from './Markdown';
import Journal from './Journal'
import Sidebar from './Sidebar';
import ReactMarkdown from 'react-markdown';
import Youtube from './Youtube'
import ImageDisplay from './ImageDisplay'
import "./CSS/FullPageExercisePage.css";
const yaml = require('js-yaml');

class FullExercisePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allFiles: props.allFiles,
            subDirectories: props.directoryConfig,
            currPages: null,
            pagesList: [],
            currMarkdown: "",
            pageID: "NO_PAGE",
            directory: props.directory,
            youtubeLink: "None",
            youtubeStartTime: 0,
            imageURL:"undefined"
        };
        // this.onSubDirectoryClick = this.onSubDirectoryClick.bind(this);
        this.pagesClick = this.pagesClick.bind(this);
        this.floatSidebar = this.floatSidebar.bind(this);
    }

    componentDidMount() {
        let sidebarDiv = window.document.getElementById("root");
        //sidebarDiv.addEventListener('scroll', this.floatSidebar);
        let container = window.document.getElementById("page_data");
        //container.addEventListener('click', this.hideSidebar);
        const localAccess = window.localStorage.getItem("fileDirectory");
        const test = this.state.subDirectories;
        if (this.props.landingPages !== undefined) {
            //this.setState({currMarkdown: this.props.landingPages.subdirectories["LandingPageList"].pages[`landingPg_${this.props.directory}.yaml`].text})
        }
        if (localAccess !== null && (this.state.subDirectories === undefined || this.state.subDirectories === {})) {
            let localObject = JSON.parse(localAccess)
            let currDirectory = window.localStorage.getItem("currDirectory")
            let markdown = window.localStorage.getItem("currMarkdown")
            let page = window.localStorage.getItem("pageID")
            let loadYoutube = window.localStorage.getItem("youtubeLink")
            let loadStartTime = window.localStorage.getItem("youtubeStartTime")
            let loadImage = window.localStorage.getItem("imageURL")
            if (markdown === null || markdown === undefined) {
                markdown = ""
            }
            this.setState({subDirectories: localObject[currDirectory], currMarkdown: markdown, pageID: page, youtubeLink: loadYoutube, youtubeStartTime: loadStartTime, imageURL: loadImage})
        } else {
            window.localStorage.setItem("currDirectory", this.props.directory)
        }
    }

    //Important when Navigating between various tabs, ensures proper reload of component when switching topics by comparing the props
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.landingPages === undefined) {
            // let markdown = window.localStorage.getItem("currMarkdown")
            // if (markdown !== undefined && markdown !== null) {
            //     return ({ currMarkdown: markdown })
            // }
            console.log("something")
            return null
        }
        if ((prevState.directory !== nextProps.directory) || prevState.pageID === "NO_PAGE") {
            window.localStorage.setItem("currMarkdown", nextProps.landingPages.subdirectories["LandingPageList"].pages[`landingPg_${nextProps.directory}.yaml`].text)
            window.localStorage.setItem("pageID", "NO_PAGE")
            window.localStorage.setItem("youtubeLink","None")
            window.localStorage.setItem("youtubeStartTime", 0)
            window.localStorage.setItem("imageURL", "undefined")
            return ({ currMarkdown: nextProps.landingPages.subdirectories["LandingPageList"].pages[`landingPg_${nextProps.directory}.yaml`].text,
              directory: nextProps.directory,
              pageID: "NO_PAGE",
              youtubeLink: "None",
              youtubeStartTime: 0,
              imageURL: "undefined"

            }) // <- this is setState equivalent
        }
        return null
      }

    floatSidebar = () => {
        var myDiv = document.getElementById('sidebar');
        var distanceFromTop = myDiv.getBoundingClientRect().top;

        if (distanceFromTop <= 50) {
            myDiv.classList.add('fixed');
        } else {
            myDiv.classList.remove('fixed');
        }
    }

    
    hideSidebar = () => {
        // const sidebar = document.getElementById("sidebar_wrapper");
        // const container = document.getElementById("page_data");
        // console.log('Running hide sidebar')
        // sidebar.style.display = "none";
        // container.style.width = "100%";
    }

    //Sets the markdown and pageID in both local storage and the state. Simulates changing of pages by loading new text and journal entries
    pagesClick = (text, pageID, youtube, startTime, image) => {
        window.localStorage.setItem("currMarkdown", text)
        window.localStorage.setItem("pageID", pageID)
        window.localStorage.setItem("youtubeLink", youtube)
        window.localStorage.setItem("youtubeStartTime", startTime)
        window.localStorage.setItem("imageURL", image)
        this.setState({currMarkdown: text, pageID: pageID, youtubeLink: youtube, youtubeStartTime: startTime, imageURL: image})
        const sidebarWrap = document.getElementById('sidebar_wrapper');
        if (sidebarWrap !== null && sidebarWrap !== undefined && sidebarWrap.style.display !== "none") {
            sidebarWrap.style.display = "none";
        }
    }


    render() {
        let { accessToken, username, directory, directoryConfig, allFiles } = this.props;
        let { subDirectories, pagesList, currMarkdown, pageID, youtubeLink, youtubeStartTime, imageURL } = this.state;
        const currJournalID = String(Date.now());
        // console.log("DirectoryConfig:", this.props.directoryConfig)
        // console.log("Access token: " + accessToken);
        window.localStorage.setItem("currDirectory", this.props.directory)
        return (
            <div id='FullPageExercise' className="FullPageExercise">
                {accessToken !== "No token" ? (
                    <div className="container">
                        {subDirectories !== undefined && subDirectories !== [] &&
                            <div id='sidebar_wrapper' className="sidebar_wrapper">
                                <div id="sidebar" className="sidebar">
                                    <Sidebar  allFiles={allFiles} mainDirectory={directoryConfig}
                                              onClick={this.pagesClick} directory={directory}></Sidebar>
                                </div>
                            </div>
                        }
                        {/* {pagesList &&
                    <div>
                        <Sidebar subDirectories={pagesList} onClick={this.pagesClick}></Sidebar>
                    </div>
                } */}
                        <div id='page_data' className="page_data">
                            {(imageURL !== undefined && imageURL !== "undefined" && imageURL !== "") &&
                                <ImageDisplay imageUrl={imageURL} />
                            }
                            <div>
                                <ReactMarkdown class='main-content'>{currMarkdown}</ReactMarkdown>
                            </div>
                            {(youtubeLink !== "None" && youtubeLink !== undefined && youtubeLink !== "undefined" && youtubeLink !== "") &&
                                <div>
                                    <Youtube link={youtubeLink} startTime={youtubeStartTime} />
                                </div>
                            }
                            
                            {pageID !== "NO_PAGE" &&
                                <div className="journal_box">
                                    <Journal class='journal' key={pageID} pageID={pageID} accessToken={accessToken} username={username}></Journal>
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3 className="sign-in-prompt">Please sign in!</h3>
                    </div>
                )
                }
            </div>
        );
    }
}

export default FullExercisePage;

// import React, { useEffect } from 'react';
//
// function MyComponent() {
//     useEffect(() => {
//         function handleScroll() {
//             if (window.scrollY >= 60) {
//                 console.log("The top of the window is at least 60px down from the top of the page");
//                 // Do something else here...
//             } else {
//                 console.log("The top of the window is less than 60px down from the top of the page");
//                 // Do something else here...
//             }
//         }
//
//         window.addEventListener('scroll', handleScroll);
//
//         return () => window.removeEventListener('scroll', handleScroll);
//     }, []);
//
//     return (
//         // Your component JSX here...
//     );
// }


