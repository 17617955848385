import React, { Component } from "react";
import RichTextEditor from "./RichTextEditor";
import parse from "html-react-parser";
import { FaPencilAlt, FaTrashAlt, FaSave } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

class JournalTextEntry extends Component {
    constructor(props) {
        super(props);

        let initVal = props.entry;
        if (initVal === null || initVal === undefined) {
            initVal = ""
        }
        this.state = {
            journalEntry: initVal,
            successMessage: "",
            paragraphToggle: props.paragraphToggle,
            prevMessage: ""
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleEntryChange = this.handleEntryChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleSave() {
        const { accessToken, username, currJournalID, pageID } = this.props;
        const { journalEntry } = this.state;
        // Save the journal entry to a database or perform any other necessary action
        console.log('Saving journal entry:', journalEntry);
        fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/journalentry', {
            method: 'POST',
            headers: {
                "Authorization": accessToken,
                "Accept": 'application/json'
            },
            body: JSON.stringify({
                "journal_id": currJournalID,
                "entry": journalEntry,
                "username": username,
                "pageID": pageID
            })
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.props.saveRefresh();
                this.setState({successMessage: "Entry Saved!"})
            })
            .catch(error => {
                console.error(error)
            });
    }

    handleEntryChange(event) {
        // Update the state variable with the new journal entry text
        console.log(event.target.getContent())
        this.setState({ journalEntry: event.target.getContent(), successMessage: ""});
    }

    handleDelete(event) {
        this.props.deleteJournalEntry(this.props.currJournalID)
    }

    handleEdit() {
        if (this.state.paragraphToggle) {
            this.setState({paragraphToggle: false, prevMessage: this.state.journalEntry})
        } else {
            this.handleSave();
            this.setState({paragraphToggle: true})
        }
    }

    handleCancel() {
        this.setState({paragraphToggle: true, journalEntry: this.state.prevMessage})
    }

    render() {
        const { accessToken, deleteJournalEntry, currJournalID, key } = this.props;
        const { journalEntry, successMessage, paragraphToggle } = this.state;
        return (
            <div id="entry-outer-layer">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h5 className="journal-date">{new Date(parseInt(currJournalID)).toDateString()}</h5>
                    <div style={{display: 'flex'}}>
                        <div className="save_delete_buttons">
                            {!paragraphToggle ? (
                                <div>
                                    <button className="save-delete-button" onClick={() => this.handleEdit()}><FaSave size="1.5em" className="icon"/></button>
                                    <button className="save-delete-button" onClick={() => this.handleCancel()}><ImCancelCircle size="1.5em" className="icon"/></button>
                                </div>
                            ) : (
                                <div>
                                    <button className="save-delete-button" onClick={() => this.handleEdit()}><FaPencilAlt size="1.5em" className="icon"/></button>
                                    <button className="save-delete-button" onClick={() => this.handleDelete()}><FaTrashAlt size="1.5em" className="icon"/></button>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="entry-list">
                    { accessToken && currJournalID && (
                        <div id="journal-text-entry">
                            {paragraphToggle ? (
                                <div className="entry">
                                    {parse(journalEntry)}
                                </div>
                            ) : (
                                <div className="entry">
                                    <RichTextEditor initVal={journalEntry} style={{width: '100px'}} onChange={this.handleEntryChange}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default JournalTextEntry;

