import React, { Component } from 'react';
import Sidebar from "./Sidebar";
import ReactMarkdown from "react-markdown";
import Journal from "./Journal";
import DailyQuote from "./DailyQuote";

class HomePage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { accessToken, dailyQuotes, allFiles, directoryConfig } = this.props;
        let quotesText = ""
        if (dailyQuotes !== "" && dailyQuotes !== undefined) {
            quotesText = dailyQuotes.DailyQuotes.subdirectories.DailyQuoteList.pages['DailyQuotes.yaml']['text']
        }
        return (
            <div id="FullPageExercise" className="home-welcome">
                <div className="container">
                    <div id='sidebar_wrapper' className="sidebar_wrapper">
                        <div id="sidebar" className="sidebar">
                            <Sidebar  allFiles={allFiles} mainDirectory={directoryConfig}
                                      onClick={this.pagesClick} directory={"Home"}></Sidebar>
                        </div>
                    </div>
                    <div id='page_data' className="page_data">
                        <h1 style={{textAlign:'center'}}>Welcome to Become and Belong!</h1>
                        {accessToken !== "" && dailyQuotes !== "" && accessToken && dailyQuotes ? (
                            <DailyQuote accessToken={accessToken} dailyQuotes={quotesText}/>
                        ) : (
                            <h2>Please Sign In!</h2>
                        )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;