import React, { Component, useState } from 'react';
import JournalTextEntry from "./JournalTextEntry";
import PreviousJournalList from "./PreviousJournalList";
import {TfiPencilAlt} from "react-icons/tfi";

class DailyQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quote: ""
        }
    }

    componentDidMount() {
        const fetchData = () => {
            const MILLISECONDS_IN_A_DAY = 1000*3600*24
            fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/quote', {
                method: 'GET',
                headers: {
                    Authorization: this.props.accessToken,
                    Accept: 'application/json'
                },
            })
                .then(response => response.json())
                .then(json => {
                    let quote = json.quote[0][1]
                    console.log(Date.now() + " " + json.quote[0][0])
                    if (parseInt(json.quote[0][0]) < (Date.now() - (MILLISECONDS_IN_A_DAY))) {
                        let quotes = this.props.dailyQuotes.split("-^-\n");
                        while (quote === json.quote[0][1]) {
                            quote = quotes[Math.floor(Math.random() * (quotes.length - 2)) + 1]
                        }
                        console.log(quote)
                        fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/quote', {
                            method: 'POST',
                            headers: {
                                Authorization: this.props.accessToken,
                                Accept: 'application/json'
                            },
                            body: JSON.stringify({
                                "old_timestamp": json.quote[0][0],
                                "new_timestamp": Date.now(),
                                "quote": quote
                            })
                        })
                            .then(response => response.json())
                            .then(json => {
                                console.log(JSON.stringify(json));
                            })
                            .catch(error => {
                                console.error(error)
                            });
                    }
                    this.setState({quote: quote})
                })
                .catch(error => {
                    console.log(error)
                });
        };
        // Call fetchData and wait for the promise to resolve before continuing
        fetchData()
    }

    render() {
        const { accessToken } = this.props;
        const { quote } = this.state;

        return (
            <div id="journal" style={{alignSelf: "center"}}>
                {accessToken !== "No token" ? (
                    <div id="journal-box">
                        <h3>Daily Quote</h3>
                        <p>{quote}</p>
                    </div>
                ) : (
                    <h3 className="sign-in-prompt">Please sign in!</h3>
                )}
            </div>
        );
    }
}

export default DailyQuote;
