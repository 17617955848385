import './App.css';
import React, {useEffect, useState} from 'react';
import jwt_decode from "jwt-decode";
import jwt_verify from "jwt-decode";
import Navbar from "./components/Nav";
import SignInButton from "./components/SignInButton";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./components/HomePage";
import BelongPage from "./components/Belong";
import MCpage from "./components/multiple_choice";
import Journal from "./components/Journal";
import FullExercisePage from './components/FullExercisePage';
import JournalPage from "./components/JournalPage";
import SnazzySuccesses from './components/SnazzySuccesses';

function App() {
    const [urlParam, setUrlParam] = useState("");
    const [accessToken, setAccessToken] = useState("")
    const [data, setData] = useState(null);
    const [error, setError] = useState("")
    const [notAuth, setNotAuth] = useState("Please Sign In!");
    const [score, setScore] = useState(0);
    const [username, setUsername] = useState("");
    const [fileDirectory, setFileDirectory] = useState("");

    document.title = 'BecomeAndBelong';

    const handleButtonClick = () => {
        if (accessToken === "") {
            setError("No Access Token")
            return
        }
        fetch(' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/transactions', {
            method: 'GET',
            headers: {
                authorization: accessToken,
                Accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => {
                    console.error(error)
                    setError("There was an error: " + error)
                }
            );
    };

    useEffect(() => {
        const localAccess = window.localStorage.getItem("access_token");
        //GET THE URL
        const url = String(window.location.href);
        console.log(url);
        if (url.indexOf("=") < 0 && localAccess == null) {
            setAccessToken("No token")
            return
        }
        let accessTokenIndex = ""
        if (url.indexOf("=") >= 0) {
            //GET THE ACCESS AND ID TOKENS FROM URL
            accessTokenIndex = url.split("access_token=")[1].split("&")[0];
            //ADD TOKEN TO LOCAL STORAGE
            window.localStorage.setItem("access_token", accessTokenIndex);
            //idTokenIndex = url.split("id_token=")[1].split("&")[0]
        } else {
            accessTokenIndex = localAccess;
        }
        console.log("Access:" + accessTokenIndex);
        //VERIFY ACCESS TOKEN
        try {
            jwt_verify(accessTokenIndex);
        } catch(err) {
            setNotAuth("Not Authorized. Please Sign In!");
            return
        }
        //SET ACCESS, DECODE ACCESS, AND SET USERNAME
        setAccessToken(accessTokenIndex);
        const decodedAccess = jwt_decode(accessTokenIndex);
        setUsername(decodedAccess.username);
        //ADD USER TO THE DATABASE IF NEEDED
        fetch(' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/transactions?' + new URLSearchParams({
            user_id: decodedAccess.username
        }), {
            method: 'POST',
            headers: {
                Authorization: accessTokenIndex,
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => {
                    console.error(error)
                    setError("There was an error: " + error)
                    setAccessToken("No token")
                }
            );

        // GRAB THE DIRECTORIES AND PAGES FROM THE BACKEND LAMBDA
        const fetchData = async () => {
            await fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/pages', {
            method: 'GET',
            headers: {
                Authorization: accessTokenIndex,
                Accept: 'application/json'
            },
        })
            .then(response => response.json())
            .then(json => {
                setFileDirectory(json);
                //console.log("File Directory:" + JSON.stringify(json));
                // All page data is stored in the "fileDirectory" in local storage for later access
                window.localStorage.setItem("fileDirectory", JSON.stringify(json))
            })
            .catch(error => {
                console.error(error)
            });
        }
        fetchData()
        console.log("File Directory:" + fileDirectory);
    }, []);


    const handleInputChange = (event) => {
        const newScore = parseInt(event.target.value);
        setScore(newScore);
    };

    function handleSubmitScoreButtonClick(event) {
        const decodedAccess = jwt_decode(accessToken);
        fetch(' https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/transactions?' + new URLSearchParams({
            user_id: decodedAccess.username,
            score: score
        }), {
            method: 'POST',
            headers: {
                Authorization: accessToken,
                Accept: 'application/json',
            }
        })
            .then(response => response.json())
            .then(json => setData(json))
            .catch(error => {
                    console.error(error)
                    setError("There was an error: " + error)
                }
            );
    }

    return (
        <div className="page_body">
            <BrowserRouter>
                <Navbar accessToken={accessToken} directoryConfig={fileDirectory}/>
                <Routes>
                    <Route path="/" element={<HomePage allFiles={fileDirectory} directoryConfig={fileDirectory.Become} accessToken={accessToken} dailyQuotes={fileDirectory}/>}/>
                    {accessToken && <Route path="/home" element={<HomePage accessToken={accessToken} dailyQuotes={fileDirectory}/>}/>}
                    <Route path="/MC" element={<MCpage/>}/>
                    <Route path="/journal" element={<JournalPage allFiles={fileDirectory} directoryConfig={fileDirectory.Become} pageID={"All Pages"} accessToken={accessToken} username={username}/>}/>
                    {accessToken && <Route path="/become" key={"become"} element={<FullExercisePage allFiles={fileDirectory} directory={"Become"} directoryConfig={fileDirectory.Become} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/belong" key="belong" element={<FullExercisePage allFiles={fileDirectory} directory={"Belong"} directoryConfig={fileDirectory.Belong} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/GrowthIdeas" key="growth_ideas" element={<FullExercisePage allFiles={fileDirectory} directory={"GrowthIdeas"} directoryConfig={fileDirectory.GrowthIdeas} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/mindfulness" key="mindfulness" element={<FullExercisePage allFiles={fileDirectory} directory={"Mindfulness"} directoryConfig={fileDirectory.Mindfulness} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/relationalgrowth" key="relational_growth" element={<FullExercisePage allFiles={fileDirectory} directory={"RelationalGrowth"} directoryConfig={fileDirectory.RelationalGrowth} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/bodyimage" key="body_image" element={<FullExercisePage allFiles={fileDirectory} directory={"BodyImage"} directoryConfig={fileDirectory.BodyImage} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/lifeperspective" key={"life_perspective"} element={<FullExercisePage allFiles={fileDirectory} directory={"LifePerspective"} directoryConfig={fileDirectory.LifePerspective} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/senseofself" key="sense_of_self" element={<FullExercisePage allFiles={fileDirectory} directory={"SenseOfSelf"} directoryConfig={fileDirectory.SenseOfSelf} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/communication" key="communication" element={<FullExercisePage allFiles={fileDirectory} directory={"Communication"} directoryConfig={fileDirectory.Communication} landingPages={fileDirectory.LandingPages} accessToken={accessToken} username={username}/>}/>}
                    {accessToken && <Route path="/SnazzySuccesses" key="snazzy" element={<SnazzySuccesses allFiles={fileDirectory} directoryConfig={fileDirectory.Become} accessToken={accessToken} username={username}/>}/>}

                </Routes>
            </BrowserRouter>
            {/* {accessToken ? (
                <div>
                    <div>
                        The value of the URL parameter "paramName" is: {urlParam}
                    </div>
                    <div>
                        <button onClick={handleButtonClick}>Fetch Data</button>
                        {data ? (
                            <pre>{JSON.stringify(data, null, 2)}</pre>
                        ) : (
                            <p>{error}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="integer-input">Enter an integer:</label>
                        <input
                            type="number"
                            id="integer-input"
                            value={score}
                            onChange={handleInputChange}
                        />
                        <p>Current score: {score}</p>
                    </div>
                    <div>
                        <button onClick={handleSubmitScoreButtonClick}>Submit Score</button>
                    </div>
                </div>
            ) : (
                <p>{notAuth}</p>
            )} */}
        </div>
    );
}

export default App;
