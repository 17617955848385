import React from "react";

const SignInButton = () => {
    const handleSignIn = () => {
        window.location.href = "https://mpa.auth.us-east-2.amazoncognito.com/login?client_id=6fb9lrcqodtpn4r0bv4jfmi5ru&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://becomeandbelong.app";
    };

    return (
        <button className="sign-in-button" onClick={handleSignIn}>
            Sign In
        </button>
    );
};

export default SignInButton;
