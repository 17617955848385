import React, { useState, useEffect } from 'react';
import "./CSS/FullPageExercisePage.css";
import "./CSS/SnazzySuccess.css";
import Sidebar from "./Sidebar";


function SnazzySuccesses({ username, accessToken, allFiles, directoryConfig, pagesClick }) {
  const [items, setItems] = useState([]); // store the items from the JSON file
  const [selectedItems, setSelectedItems] = useState([]); // store the selected items
  const [completedItems, setCompletedItems] = useState([]);
  const [todoItems, setTodoItems] = useState([]);
  const [selectedTodos, setSelectedTodos] = useState([]);
  const [remainingItems, setRemainingItems] = useState([]);
  const [selectedComplete, setSelectedComplete] = useState([]);
  const [showPoints, setShowPoints] = useState(false);



  useEffect(() => {
    // fetch the JSON data from a file and update the state with the parsed data
    fetch('/SnazzySuccesses.json')
      .then(response => response.json())
      .then(data => {
        setItems(data.SnazzySuccessList)
        console.log("JSON", data)
        loadFromDB(data.SnazzySuccessList)
      })
      .catch(error => console.error('Error fetching data:', error));

    
  }, []);

  function loadFromDB(data){
    //FETCH THE DATABASE STUFF
    fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/save-saved-successes/' + username, {
      method: 'GET',
      headers: {
          Authorization: accessToken,
          Accept: 'application/json'
      },
  })
      .then(response => response.json())
      .then(json => {
        // let saved_successes = JSON.parse(json)
          if(json.saved_successes[0][1] !== null){
            console.log("Table column 1", json.saved_successes[0][1])
            setTodoItems(data.filter(item => json.saved_successes[0][1].includes(item.id)));
          }
          if(json.saved_successes[0][2] !== null)
          setCompletedItems(data.filter(item => json.saved_successes[0][2].includes(item.id)))
          setRemainingItems(data.filter(n => !json.saved_successes[0][2].concat(json.saved_successes[0][1]).includes(n)))
          console.log("DB stuff", JSON.stringify(json));
      })
      .catch(error => {
          console.error(error)
      });
  }

  function handleItemSelection(item) {
    // add or remove the selected item from the list based on whether it is already selected
    setSelectedItems(selectedItems.includes(item) 
      ? selectedItems.filter(selected => selected !== item)
      : [...selectedItems, item]);
  }

  function handleTodoSelection(item) {
    // add or remove the selected item from the list based on whether it is already selected
    setSelectedTodos(selectedTodos.includes(item) 
      ? selectedTodos.filter(selected => selected !== item)
      : [...selectedTodos, item]);
  }

  function handleCompletedSelection(item) {
        // add or remove the selected item from the list based on whether it is already selected
        setSelectedComplete(selectedComplete.includes(item) 
        ? selectedComplete.filter(selected => selected !== item)
        : [...selectedComplete, item]);
  }

  function AddSuccessesToPersonalListDatabase(successList){
    setTodoItems(successList.concat(todoItems))
    setRemainingItems(remainingItems.filter(n => !successList.includes(n)))
    let idNumbers = successList.concat(todoItems).map(obj => obj.id);
    postTodosToDB(idNumbers)
    setSelectedItems([])
  }

  // Code for adding successes to the database
  function postTodosToDB(updatedList){
    fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/save-saved-successes', {
      method: 'POST',
      headers: {
          Authorization: accessToken,
          Accept: 'application/json'
      },
      body: JSON.stringify({
          "username": username,
          "data": JSON.stringify(updatedList)
      })
    })
      .then(response => response.json())
      .then(json => {
          console.log(JSON.stringify(json));
      })
      .catch(error => {
          console.error(error)
      });
  }

  function AddSuccessesToPersonalCompletedListDatabase(successList){
    console.log("SuccessList", successList)
    setCompletedItems(successList.concat(completedItems))
    console.log("Whole list", successList.concat(completedItems))
    setTodoItems(todoItems.filter(n => !successList.includes(n)))
    console.log("New todo list", todoItems.filter(n => !successList.includes(n)))
    postTodosToDB((todoItems.filter(n => !successList.includes(n))).map(obj => obj.id))
    let idNumbers = successList.concat(completedItems).map(obj => obj.id);
    postCompletedToDB(idNumbers);
    setSelectedTodos([])
  }

  function postCompletedToDB (updatedList){
    fetch('https://k4ro5pm6f1.execute-api.us-east-2.amazonaws.com/test/save-completed-successes', {
      method: 'POST',
      headers: {
          Authorization: accessToken,
          Accept: 'application/json'
      },
      body: JSON.stringify({
          "username": username,
          "data": JSON.stringify(updatedList)
      })
    })
      .then(response => response.json())
      .then(json => {
          console.log(JSON.stringify(json));
      })
      .catch(error => {
          console.error(error)
      });
  }

  function markIncompleteToDB(deleteList){
    let updatedCompletedList = (completedItems.filter(n => !deleteList.includes(n))).map(obj => obj.id)
    setCompletedItems(updatedCompletedList)
    postCompletedToDB(updatedCompletedList)
    let updatedTodoList = todoItems.concat(deleteList)
    postTodosToDB(updatedTodoList)
    setTodoItems(updatedTodoList)
    setSelectedComplete([])
  }

  function deleteTodoFromDB(deleteList){
    let updatedTodoList = todoItems.filter(n => !deleteList.includes(n)).map(obj => obj.id)
    postTodosToDB(updatedTodoList)
    setTodoItems(updatedTodoList)
    setSelectedTodos([])
  }

  function addSingleSnazzy(item){
    todoItems.push(item)
    setRemainingItems(remainingItems.filter(n => ![item].includes(n)))
    let idNumbers = todoItems.map(obj => obj.id);
    postTodosToDB(idNumbers)
  }


  return (
    <div className="FullPageExercise">
        <div className="container">
            <div id='sidebar_wrapper' className="sidebar_wrapper">
                <div id="sidebar" className="sidebar">
                    <Sidebar  allFiles={allFiles} mainDirectory={directoryConfig}
                              onClick={pagesClick} directory={"SnazzySuccesses"}></Sidebar>
                </div>
            </div>
            {/* {pagesList &&
                    <div>
                        <Sidebar subDirectories={pagesList} onClick={this.pagesClick}></Sidebar>
                    </div>
                } */}
            <div id='page_data' className="page_data">
                <div id="snazzy-page">
                    {!showPoints && <button onClick={() => setShowPoints(true)}>Show Points</button>}
                    {showPoints && <button onClick={() => setShowPoints(false)}>Hide Points</button>}
                    <h2>My Completed Successees:</h2>
                    {showPoints && <h2>({completedItems.reduce((total, item) => total + item.pts, 0)})</h2>}

                    <ul>
                        {completedItems.map(selected => (
                            <li key={selected.id}>
                              <label>
                                <input
                                    type="checkbox"
                                    value={selected.id}
                                    onChange={() => handleCompletedSelection(selected)}
                                />
                                {showPoints && selected.pts + " pts - "}
                                {selected.prompt}
                              </label>
                            </li>
                        ))}
                    </ul>
                    {selectedComplete.length > 0 && <button onClick={() => markIncompleteToDB(selectedComplete)}>Delete Selected</button>}
                    <h2>To Do:</h2>
                    {showPoints && <h2>({todoItems.reduce((total, item) => total + item.pts, 0)})</h2>}
                    <ul>
                        {todoItems.map(selected => (
                            <li key={selected.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={selected.id}
                                        onChange={() => handleTodoSelection(selected)}
                                    />
                                    {showPoints && selected.pts + " pts - "}
                                    {selected.prompt}
                                </label>
                            </li>
                        ))}
                    </ul>
                    {selectedTodos.length > 0 && <button onClick={() => AddSuccessesToPersonalCompletedListDatabase(selectedTodos)}>Mark Completed</button>}
                    {selectedTodos.length > 0 && <button onClick={() => deleteTodoFromDB(selectedTodos)}>Delete Selected</button>}
                    <h2>Snazzy Successes:</h2>
                    <ul>
                        {items && remainingItems.map(item => (
                            <li key={item.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={item.id}
                                        onChange={() => handleItemSelection(item)}
                                    />
                                    {showPoints && item.pts + " pts - "}
                                    {item.prompt}
                                    <span
                                      className="plus-icon"
                                      onClick={() => addSingleSnazzy(item)}
                                    >
                                      &#43;
                                    </span>
                                </label>
                            </li>
                        ))}
                    </ul>
                    {selectedItems.length > 0 && <button onClick={() => AddSuccessesToPersonalListDatabase(selectedItems)}>Add to personal sublist</button>}
                </div>
            </div>
        </div>
    </div>
  );
}

export default SnazzySuccesses;







// import React, { useState } from 'react';
// import YAML from 'yaml';
// import yml from 'js-yaml';
// import { Json } from 'aws-sdk/clients/marketplacecatalog';

// const yamlData = `
// - name: Item 1
//   description: This is item 1.
// - name: Item 2
//   description: This is item 2.
// - name: Item 3
//   description: This is item 3.
// `;

// const SnazzySuccesses = () => {
//   const [items, setItems] = useState(0);  
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [yams, setYams] = React.useState('...loading');
//   // fetch('snazzySuccesses.json')
//   //   .then((response) => response.text())
//   //   .then((text) => {
//   //     const config = Json.parse(text);
//   //     console.log("Config: " + config)
//   //     // setItems(Json.stringify(config, null, 2));
//   //   });
//  fetch('snazzySuccesses.json')
//   .then(response => response.json()) // parse the JSON data from the response
//   .then(data => {
//     setItems(data)
//     console.log(data);
//   })
//   .catch(error => {
//     console.error('Error fetching JSON data:', error);
//   });



//   const handleItemClick = (item) => {
//     const isSelected = selectedItems.some((selectedItem) => selectedItem.name === item.name);
//     if (isSelected) {
//       setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.name !== item.name));
//     } else {
//       setSelectedItems([...selectedItems, item]);
//     }
//   };

//   const handleAddToListClick = () => {
//     // Do something with selectedItems, e.g. add them to a personal sublist
//     console.log(selectedItems);
//   };

//   return (
//     <div>
//       <h2>Snazzy Successes:</h2>
//       <ul>
//         {items["SnazzySuccessList"].map((item) => (
//           <li key={item.name}>
//             <label>
//               <input type="checkbox" checked={selectedItems.some((selectedItem) => selectedItem.name === item.name)} onChange={() => handleItemClick(item)} />
//               {item.name} - {item.description}
//             </label>
//           </li>
//         ))}
//       </ul>
//       <button onClick={handleAddToListClick}>Add to personal sublist</button>
//     </div>
//   );
// };

// export default SnazzySuccesses;
